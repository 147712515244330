import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { IAuthUser } from '../../Interfaces/IAuthUser';
import { MdDashboard } from 'react-icons/md';
import { FaNetworkWired, FaDiscord } from 'react-icons/fa';
import { HiUserCircle } from 'react-icons/hi';
import logo from '../../Images/logo.png';
import { AuthContext } from '../..';
import { DisconnectUser, GetStoredToken } from '../../Services/UserService';
import AdminMenu from './AdminMenu';

const links = [
  {
    label: 'Tableau de bord',
    icon: <MdDashboard className="mr-2" />,
    uri: '/',
    admin:false
  },
  {
    label: 'Lans',
    icon: <FaNetworkWired className="mr-2" />,
    uri: '/lans',
    admin:false
  },
  {
    label: 'Mon profil',
    icon: <HiUserCircle className="mr-2" />,
    uri: '/myprofil',
    admin:false
  }
];

const AuthTemplate = (props: any) => {
  let user: IAuthUser = useContext(AuthContext) as IAuthUser;

  return (
    <div className="flex">
      <div className="w-1/6 min-h-full flex flex-col shadow">
        <img
          src={logo}
          className="hidden sm:block h-28 self-center invisible sm:visible"
        />
        <div className="bg-white rounded shadow m-4 p-4 hidden sm:block">
          <div className="flex flex-col lg:flex-row space-x-2">
            <img
              src={user.steam_profil?.avatarmedium}
              alt=""
              className="rounded-md shadow h-12 w-12"
            />
            <div>
              <h2 className="font-semibold lg:text-base text-xs">
                {user.prenom} {user.nom.substring(0, 1)}.
              </h2>
              <span className="text-xs text-gray-600">{user.username}</span>
            </div>
          </div>
        </div>
        <nav className="flex flex-col h-full justify-between">
          <div>
            {links.map((l) => {
              return(
              <a
                key={l.uri}
                href={l.uri}
                className={`flex items-center py-2 px-4 m-2 rounded ${
                  window.location.pathname == l.uri ? 'menu-item-active' : ''
                }`}
              >
                <span>{l.icon}</span>
                <span className="hidden sm:block">{l.label}</span>
              </a>
            )})}
            <a
              href="https://discord.gg/2JHHEUVRKP"
              className="flex items-center py-2 px-4 m-2 rounded"
              target="_blank"
            >
              <span>
                <FaDiscord className="mr-2" />
              </span>
              <span className="hidden sm:block">Discord</span>
            </a>
            <AdminMenu user={user} />
          </div>
          <a
            href="#"
            className="text-center w-full block mb-10 text-blue-600 font-semibold"
            onClick={() => DisconnectUser()}
          >
            <span className="hidden sm:block">Se déconnecter</span>
            <span className="sm:hidden flex justify-center">
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                ></path>
              </svg>
            </span>
          </a>
        </nav>
      </div>
      <div className="w-full h-auto min-h-screen bg-white p-4">
        {props.children}
      </div>
    </div>
  );
};

export default AuthTemplate;
