import { useEffect, useState, useContext } from "react";
import { useParams, Redirect } from "react-router-dom";
import {
  ILanUser,
  ILanWithParticipants,
} from "../../Interfaces/ILanWithParticipants";
import { GetLanDetailsById } from "../../Services/LanService";

import { FaBook } from "react-icons/fa";
import {
  AiFillCheckSquare,
  AiFillCloseSquare,
  AiFillTrophy,
} from "react-icons/ai";
import { IAuthUser } from "../../Interfaces/IAuthUser";
import { AuthContext } from "../..";
import Config from "../../Config";

const ViewLan = ({}) => {
  let { id } = useParams();
  let user: IAuthUser = useContext(AuthContext) as IAuthUser;

  const [lanDetails, setLanDetails] = useState<ILanWithParticipants | null>(
    null
  );
  const [shouldRedirect, setShouldRedirect] = useState<boolean>(false);

  const [isUserParticipant, setIsUserParticipant] = useState<boolean>(false);

  useEffect(() => {
    GetLanDetailsById(id).then((lanDetail: ILanWithParticipants | null) => {
      if (lanDetail) {
        setLanDetails(lanDetail);
        setShouldRedirect(!lanDetail.lan.public && !user.admin);
        const uPart = lanDetail.participants.find(
          (u: ILanUser) => u.user.id == user.id
        );
        if (uPart) {
          setIsUserParticipant(uPart.paiement);
        }
      }
    });
  }, []);

  if(shouldRedirect){
      return <Redirect to="/"/>
  }

  if (!lanDetails) {
    return <div>La page demandée n'existe pas.</div>;
  }

  function renderButton() {
    if (lanDetails?.paid) {
      return (
        <div className="flex justify-center p-4 bg-green-400 rounded shadow font-semibold text-white">
          Vous êtes inscrit
        </div>
      );
    }
    if (
      lanDetails &&
      lanDetails.lan.participants - lanDetails.participants.length == 0
    ) {
      return (
        <div className="flex justify-center p-4 bg-yellow-400 rounded shadow font-semibold text-white">
          Malheureusement, il n'y a plus de place...
        </div>
      );
    }
    if (
      lanDetails &&
      new Date() > new Date(lanDetails?.lan.stop_at) &&
      !isUserParticipant &&
      !lanDetails.visiteur &&
      !lanDetails.paid
    ) {
      return (
        <div className="flex justify-center p-4 bg-yellow-400 rounded shadow font-semibold text-white">
          Les inscriptions sont terminées.
        </div>
      );
    }
    if (
      (!isUserParticipant &&
        lanDetails?.waiting.find((u: ILanUser) => u.user.id == user.id)) ||
      (lanDetails?.visiteur && !lanDetails.paid)
    ) {
      return (
        <a
          href={`/participe/${id}`}
          className="flex p-4 mt-4 justify-center bg-yellow-600 text-white rounded font-bold shadow-lg"
        >
          Régler les frais de participations
        </a>
      );
    }
    if (!isUserParticipant && !lanDetails?.paid && !lanDetails?.visiteur) {
      return (
        <a
          href={`/participe/${id}`}
          className="flex p-4 mt-4 justify-center bg-blue-400 text-white rounded font-bold shadow-lg"
        >
          Je participe !
        </a>
      );
    }
  }

  return (
    <div>
      <h1 className="text-2xl font-semibold">{lanDetails.lan.title}</h1>
      <h2>
        du {new Date(lanDetails.lan.start_at).toLocaleDateString()} au{" "}
        {new Date(lanDetails.lan.end_at).toLocaleDateString()}
      </h2>

      <div className="sm:flex flex-col sm:flex-row sm:justify-evenly sm:space-x-4 mt-10">
        <div className="sm:w-1/5 w-full p-4 flex flex-col bg-gray-100 shadow-lg rounded-lg items-center">
          <span className="font-bold text-3xl">
            {lanDetails.participants.length}
          </span>
          <span className="uppercase">participants</span>
        </div>
        <div className="sm:w-1/5 w-full p-4 flex flex-col bg-gray-100 shadow-lg rounded-lg items-center">
          <span className="font-bold text-3xl">
            {lanDetails.waiting.length}
          </span>
          <span className="uppercase">en attente</span>
        </div>
        <div className="sm:w-1/5 w-full p-4 flex flex-col bg-gray-100 shadow-lg rounded-lg items-center">
          <span className="font-bold text-3xl">
            {lanDetails.lan.participants - lanDetails.participants.length}
          </span>
          <span className="uppercase">places restantes</span>
        </div>
        <div className="sm:w-1/5 w-full p-4 flex flex-col bg-gray-100 shadow-lg rounded-lg items-center">
          <span className="font-bold text-3xl">
            {lanDetails.lan.participants}
          </span>
          <span className="uppercase">places totales</span>
        </div>
        <div className="sm:w-1/5 w-full p-4 flex flex-col bg-gray-100 shadow-lg rounded-lg items-center">
          <span className="font-bold text-3xl">
            {lanDetails.lan.engagement}€
          </span>
          <span className="uppercase">frais d'engagement</span>
        </div>
      </div>
      <div className="mt-10">
        <a
          target="_blank"
          href={lanDetails.lan.linktocgu}
          className="rounded shadow font-semibold p-4 flex items-center hover:text-blue-500"
        >
          <FaBook className="mr-2" /> Lire les conditions générales
        </a>
      </div>
      {renderButton()}
      <div className="mt-10">
        <h1 className="font-semibold text-lg">Description</h1>
        <p
          className="p-4 rounded shadow bg-gray-100"
          dangerouslySetInnerHTML={{ __html: lanDetails.lan.description }}
        ></p>
      </div>
      <div className="mt-10">
        <h1 className="font-semibold text-lg mb-4">Adresse</h1>
        {new Date() < new Date(lanDetails.lan.end_at) ? (
          <a
            target="_blank"
            href={`http://maps.google.fr/?q=${lanDetails.lan.address}`}
            className="sm:p-4 rounded shadow font-semibold bg-gray-100 hover:text-blue-500"
          >
            {lanDetails.lan.address.length > 0
              ? lanDetails.lan.address
              : "Non indiquée"}
          </a>
        ) : (
          <div className="p-4 rounded shadow font-semibold bg-gray-100">
            Lan terminée
          </div>
        )}
      </div>
      <div className="mt-10">
        <h1 className="font-semibold text-lg flex justify-between">
          Participants
          <div className="flex space-x-3">
            <span className="text-xs flex items-center">
              <AiFillCheckSquare className="h-6 w-6 text-green-600" />
              Vient avec son PC
            </span>
            <span className="text-xs flex items-center">
              <AiFillCloseSquare className="h-6 w-6 text-yellow-500" />
              Vient sans PC
            </span>
            <span className="text-xs flex items-center">
              <AiFillTrophy className="h-6 w-6 text-green-600" />
              Participe au tournoi
            </span>
            <span className="text-xs flex items-center">
              <AiFillTrophy className="h-6 w-6 text-yellow-500" />
              Ne participe pas au tournoi
            </span>
          </div>
        </h1>

        <div className="grid md:grid-cols-2 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 grid-cols-1 gap-4">
          {lanDetails.participants.map((p: ILanUser) => (
            <div
              className="p-4 bg-gray-100 rounded-md flex relative"
              key={p.id}
            >
              <a href={`/profil/${p.user.id}`}>
                <div className="h-16 w-16">
                  <img
                    src={p.user.steam_profil?.avatarmedium}
                    className="rounded shadow h-full w-full"
                  />
                </div>
              </a>
              <div className="flex flex-col ml-4 leading-10">
                <span className="text-xs lg:text-xl sm:text-base md:text-xs font-semibold">
                  {p.user.username}
                </span>
                <span className="text-sm">
                  {p.user.prenom} {p.user.nom.substring(0, 1)}.
                </span>
                <p className="leading-4 text-xs text-gray-400">
                  {p.commentaire}
                </p>
              </div>
              {p.equipment ? (
                <AiFillCheckSquare className="absolute top-3 right-3 h-6 w-6 text-green-600" />
              ) : (
                <AiFillCloseSquare className="absolute top-3 right-3 h-6 w-6 text-yellow-500" />
              )}
              {p.tournament ? (
                <AiFillTrophy className="absolute top-3 right-9 h-6 w-6 text-green-600" />
              ) : (
                <AiFillTrophy className="absolute top-3 right-9 h-6 w-6 text-yellow-500" />
              )}
            </div>
          ))}
          {lanDetails.participants.length == 0 && (
            <div>Il n'y a pas encore de participant...😥</div>
          )}
        </div>

        <div className="mt-10">
          <h1 className="font-semibold text-lg">En attente de validation</h1>

          <div className="grid md:grid-cols-2 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 grid-cols-1 gap-4">
            {lanDetails.waiting.map((p: ILanUser) => (
              <div
                className="p-4 bg-gray-100 rounded-md flex relative"
                key={p.id}
              >
                <a href={`/profil/${p.user.id}`}>
                  <div className="h-16 w-16">
                    <img
                      src={p.user.steam_profil?.avatarmedium}
                      className="rounded shadow h-full w-full"
                    />
                  </div>
                </a>
                <div className="flex flex-col ml-4 leading-10">
                  <span className="text-xs lg:text-xl sm:text-base md:text-xs font-semibold">
                    {p.user.username}
                  </span>
                  <span className="text-sm">
                    {p.user.prenom} {p.user.nom.substring(0, 1)}.
                  </span>
                </div>
              </div>
            ))}
            {lanDetails.waiting.length == 0 && (
              <div>Il n'y a personne en attente</div>
            )}
          </div>
        </div>
      </div>
      {user.admin && (
        <>
          <div className="mt-10">
            <h1 className="font-semibold text-lg">
              Visiteurs{" "}
              <span className="text-xs text-gray-500">
                (seuls les orgas peuvent voir ça)
              </span>
            </h1>

            <div className="grid md:grid-cols-2 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 grid-cols-1 gap-4">
              {lanDetails.visiteurs.map((p: ILanUser) => (
                <div
                  className="p-4 bg-gray-100 rounded-md flex relative"
                  key={p.id}
                >
                  <div className="flex flex-col leading-10">
                    <span className="text-xs lg:text-xl sm:text-base md:text-xs font-semibold">
                      {p.user.username}
                    </span>
                    <span className="text-sm">
                      {p.user.prenom} {p.user.nom.substring(0, 1)}.
                    </span>
                    <p className="leading-4 text-xs text-gray-400">
                      {p.commentaire}
                    </p>
                  </div>
                </div>
              ))}
              {lanDetails.visiteurs.length == 0 && (
                <div className="mb-10">Il n'y a pas de visiteurs</div>
              )}
            </div>
          </div>
          <a
            target="_blank"
            href={`${Config.baseURL}/document/lan/${lanDetails.lan.id}`}
            className="flex p-4 mt-4 justify-center bg-blue-400 text-white rounded font-bold shadow-lg"
          >
            Imprimer la liste des joueurs
          </a>
        </>
      )}
    </div>
  );
};

export default ViewLan;
