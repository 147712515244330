import moment from "moment";
import Config from "../Config";
import { GetStoredToken } from "./UserService";

const GetUsers = async (): Promise<any> => {
  const token = GetStoredToken()[1];
  if (!token) return;
  const req = await fetch(`${Config.baseURL}/admin/users`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (req.status === 200) {
    return await req.json();
  }
};

const GetBalance = async (): Promise<any> => {
  const token = GetStoredToken()[1];
  if (!token) return;
  const req = await fetch(`${Config.baseURL}/paypal/balance`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (req.status === 200) {
    return await req.json();
  }
};

const GetTransactions = async (
  start: Date = new Date(),
  end: Date = new Date()
): Promise<any> => {
  const token = GetStoredToken()[1];
  if (!token) return;
  const req = await fetch(
    `${Config.baseURL}/paypal/transactions/${moment(
      start
    ).toISOString()}/${moment(end).toISOString()}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (req.status === 200) {
    return await req.json();
  }
};

export { GetUsers, GetBalance, GetTransactions };
