import { useEffect, useState } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { IAuthUser } from '../../Interfaces/IAuthUser';
import { GetUsersByGameAppId, IGameUsers } from '../../Services/GameService';
import UserGame from './UserGame';

const Game = () => {
  let { id } = useParams();

  const [infos, setInfos] = useState<IGameUsers>();

  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    GetUsersByGameAppId(id).then((users: IGameUsers | undefined) => {
      if (!users) {
        setError(true);
      }
      setInfos(users);
    });
  }, []);

  if (error) {
    return <Redirect to="/" />
  }

  if (!infos) {
    return <div>Chargement...</div>
  }

  return (
    <div>
      <div className="relative h-28 w-full bg-cover bg-no-repeat bg-center rounded-xl flex flex-col bg-gray-900" style={{ backgroundImage: `url(https://cdn.akamai.steamstatic.com/steam/apps/${infos.game.app_id}/page_bg_generated_v6b.jpg?t=1629963496)` }}>
        <h1 className="text-center text-gray-200 mt-7 font-semibold">{infos.game.name}</h1>
        <img src={infos.game.logo_url} className="h-14 sm:h-20 self-center absolute -bottom-5 rounded border-2 border-green-400" />
        <div className="text-white flex justify-between absolute bottom-0 left-0 right-0 mx-5 mb-1">
          <span className="font-semibold">{infos.users.length} joueurs</span>
          <span></span>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mt-10 gap-4">
        {infos.users.map((u: IAuthUser) => <UserGame key={u.id} user={u} />)}
      </div>
    </div>
  );
};

export default Game;
