import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../..";
import { IAuthGame } from "../../Interfaces/IAuthGame";
import { IAuthUser } from "../../Interfaces/IAuthUser";
import { GetStoredToken, GetUserById, GetUserFromToken, GetUserGamesByUserID, UpdateSteamGames } from "../../Services/UserService";

const MyProfile = () => {
    let user: IAuthUser = useContext(AuthContext) as IAuthUser;
    const [uGames, setUGames] = useState<IAuthGame[]>([]);

    const [updateSteam, setUpdateSteam] = useState<boolean>(false);


    useEffect(() => {
        GetUserById(user.id).then((u: IAuthUser | null) => {
          if (u) {
            GetUserGamesByUserID(u.id).then((games: IAuthGame[] | null) => {
              if (games) {
                setUGames(games);
              }
            }).catch(console.error);
          }
        });
      }, []);


      function updateSteamProfil(){
        setUpdateSteam(true);
        const token = GetStoredToken()[1];
        if(!token){
            return setUpdateSteam(false);
        }
        UpdateSteamGames(token).then(() => setUpdateSteam(false)).catch(() => setUpdateSteam(false));
      }

    return (
        <>
            <div className="flex flex-col items-center">
                {user.steam_profil && 
                    <img className="rounded h-40 w-40" src={user.steam_profil?.avatarfull} alt={user.steam_profil?.personaname} />
                }
                <h1 className="text-lg font-bold">{user.username}</h1>
            </div>

                <h1 className="text-xl font-bold mb-5">Votre profil privé</h1>

            <label className="block w-full">
              <span className="text-gray-700 flex justify-between">
                Pseudo
              </span>
              <input
              disabled value={user.username}
                type="text"
                className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-blue-50 focus:ring-0"
              />
            </label>
            <label className="block w-full">
              <span className="text-gray-700 flex justify-between">
                Nom
              </span>
              <input
              disabled value={user.nom}
                type="text"
                className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-blue-50 focus:ring-0"
              />
            </label>
            <label className="block w-full">
              <span className="text-gray-700 flex justify-between">
                Prénom
              </span>
              <input
              disabled value={user.prenom}
                type="text"
                className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-blue-50 focus:ring-0"
              />
            </label>
            <label className="block w-full">
              <span className="text-gray-700 flex justify-between">
                Adresse mail
              </span>
              <input
              disabled value={user.email}
                type="text"
                className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-blue-50 focus:ring-0"
              />
            </label>
            <label className="block w-full">
              <span className="text-gray-700">
                Mot de passe <i className="text-xs italic">(nombre de caractère non réel)</i>
              </span>
              <input
              disabled
              value="**********"
                type="text"
                className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-blue-50 focus:ring-0"
              />
            </label>
            <label className="block w-full">
              <span className="text-gray-700">
                Date d'inscription sur le site
              </span>
              <input
              disabled
              value={new Date(user.created_at).toLocaleDateString()}
                type="text"
                className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-blue-50 focus:ring-0"
              />
            </label>
              <h1 className="text-xl font-bold mb-5 mt-5">Votre profil Steam</h1>
            <label className="block w-full">
              <span className="text-gray-700">
                Steam ID
              </span>
              <input
              disabled
              value={user.steamid}
                type="text"
                className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-blue-50 focus:ring-0"
              />
            </label>
            {user.steam_profil &&
            <>
                <label className="block w-full">
              <span className="text-gray-700">
                Pseudo
              </span>
              <input
              disabled
              value={user.steam_profil.personaname}
                type="text"
                className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-blue-50 focus:ring-0"
              />
            </label>
                <label className="block w-full">
                    <span className="text-gray-700 flex justify-between">
                        Nombre de jeux
                    </span>
                    <input
                    disabled
                    value={uGames.length}
                        type="text"
                        className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-blue-50 focus:ring-0"
                    />
                    <a href={`/profil/${user.id}`} className="text-blue-500">Voir mes jeux</a>

                </label>
                </>
            }
                    <button disabled={updateSteam} onClick={() => updateSteamProfil()} className="flex w-full justify-center p-4 bg-green-400 disabled:bg-gray-300 rounded shadow font-semibold text-white">
                        Mettre à jour mon profil Steam
                    </button>
            
        </>
    )
}

export default MyProfile;