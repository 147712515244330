import { useEffect, useState } from 'react';
import { ILanParticipant } from '../../Interfaces/ILan';
import { GetLansList } from '../../Services/LanService';

const LansList = () => {
  const [lans, setLans] = useState<any[]>([]);

  useEffect(() => {
    GetLansList().then((lans: ILanParticipant[]) => {
      //console.log(lans);
      setLans(lans);
    });
  }, []);

  if(lans.length == 0){
    return (
      <div>Chargement en cours !</div>
    )
  }

  return (
    <>
      <h1 className="text-2xl font-semibold">Liste des lans</h1>

      <div className="space-y-4">
        {lans.map((lan: ILanParticipant) => (
          <a
            href={`/lan/${lan.lan.id}`}
            key={lan.lan.id}
            className={`lg:h-16 w-full rounded relative shadow flex px-4 hover:bg-blue-50`}
          >
            <div className="flex flex-col lg:flex-row w-full lg:justify-between lg:items-center">
              <div className="flex items-center space-x-4">
                <span>
                  {new Date(lan.lan.end_at) > new Date() && (
                    <svg
                      className="w-6 h-6 text-yellow-300 animate-spin"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                    </svg>
                  )}
                </span>
                <div>
                  <h2 className="font-semibold flex">
                    {lan.lan.title}{' '}
                    <span className="text-sm text-gray-500 ml-1 flex flex-col justify-end">
                      du {new Date(lan.lan.start_at).toLocaleDateString()} au{' '}
                      {new Date(lan.lan.end_at).toLocaleDateString()}
                    </span>
                  </h2>
                  <span>
                    {new Date(lan.lan.end_at) < new Date()
                      ? 'Lan terminée'
                      : lan.lan.address}
                  </span>
                </div>
              </div>
              <div>
                <div className="flex flex-col text-right">
                  <div>
                    <span className="font-bold text-3xl">
                      {lan.nbparticipants}
                    </span>
                    <span className="font-bold text-2xl mx-1">/</span>
                    <span className="font-semibold text-xl">
                      {lan.lan.participants}
                    </span>
                  </div>
                  {new Date(lan.lan.end_at) > new Date() && (
                    <span className="text-gray-500 text-sm">
                      Fin des inscriptions le{' '}
                      {new Date(lan.lan.stop_at).toLocaleDateString()}
                    </span>
                  )}
                  {new Date(lan.lan.stop_at) > new Date() &&
                    new Date(lan.lan.end_at) < new Date() && (
                      <span className="text-red-600">
                        La lan arrive bientôt !
                      </span>
                    )}
                </div>
              </div>
            </div>
          </a>
        ))}
      </div>
    </>
  );
};

export default LansList;
