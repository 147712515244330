
import { IEvent } from "../Components/Admin/Events/Events";
import Config from "../Config";
import { GetStoredToken } from "./UserService";

export const GetEvents = async (
  ): Promise<IEvent[] | undefined> => {
      const token = GetStoredToken()[1];
    if (!token) return;
    const req = await fetch(`${Config.baseURL}/lan/events`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (req.status === 200) {
      return await req.json();
    }
  };