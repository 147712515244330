import { useContext, useEffect, useState } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { AuthContext } from '../..';
import { IAuthGame } from '../../Interfaces/IAuthGame';
import { IAuthUser } from '../../Interfaces/IAuthUser';
import { GetUserById, GetUserGamesByUserID } from '../../Services/UserService';
import {BsShieldLockFill} from 'react-icons/bs';
import OrgaBadge from './OrgaBadge';
import moment from 'moment';

function secondsToHms(d) {
  d = Number(d);
  var h = Math.floor(d / 60);
  var m = Math.floor(d - h*60);

  var hDisplay = h > 0 ? h + (h == 1 ? " heure, " : " heures, ") : "";
  var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
  return hDisplay + mDisplay; 
}

const UserProfile = () => {
  let loggedUser: IAuthUser = useContext(AuthContext) as IAuthUser;


  let { id } = useParams();

  const [user, setUser] = useState<IAuthUser>();
  const [uGames, setUGames] = useState<IAuthGame[]>([]);

  useEffect(() => {
    GetUserById(id).then((u: IAuthUser | null) => {
      if (u) {
        console.log(u);
        setUser(u);
        GetUserGamesByUserID(u.id).then((games: IAuthGame[] | null) => {
          if (games) {
            setUGames(games);
          }
        }).catch(console.error);
      }
    });
  }, []);

  if (user) {
    return (
      <div>
        {uGames.length > 0 ?
          <>
            <div className="relative h-28 w-full bg-cover bg-no-repeat bg-center rounded-xl flex flex-col" style={{ backgroundImage: `url(https://cdn.akamai.steamstatic.com/steam/apps/${uGames[0].app_id}/page_bg_generated_v6b.jpg?t=1629963496)` }}>
              {user.admin && <OrgaBadge />}
              <div className="text-white flex justify-between absolute bottom-0 left-0 right-0 mx-5 mb-1">
                <span className="font-semibold">{user.username}</span>
                <span>{user.prenom} {user.nom.substring(0, 1)}.</span>
              </div>
              <img src={user.steam_profil?.avatarmedium} className="h-16 self-center absolute -bottom-5 rounded-xl border-2 border-blue-400" />
            </div>
            <div className="grid gap-4 sm:gap-2 m-4 mt-8">
          {uGames.map((g:IAuthGame) =>
          <div className='grid grid-cols-2'>
            <a className='flex' href={`/game/${g.app_id}`}><img src={g.icon_url} className="mr-5"/> {g.name}</a>
            <span>{secondsToHms(g.playtime_forever)}</span>
            </div>
          )}

            </div>
          </>
          : <>
            <div className="relative h-28 w-full bg-cover bg-no-repeat bg-center rounded-xl flex flex-col bg-gray-900">
            {user.admin && <OrgaBadge />}
              <div className="text-white flex justify-between absolute bottom-0 left-0 right-0 mx-5 mb-1">
                <span className="font-semibold">{user.username}</span>
                <span>{user.prenom} {user.nom.substring(0, 1)}.</span>
              </div>
              <img src={user.steam_profil?.avatarmedium} className="h-16 self-center absolute -bottom-5 rounded-xl border-2 border-blue-400" />
            </div>
            <p className="mt-10">
              Ce profil n'a pas de jeu, ou son profil Steam est privé.
            </p>
          </>
        }
        {loggedUser.admin && 
              <div className='mt-5 mb-5 w-full border-2 border-blue-400 rounded p-4'>
                <h1 className='flex items-center justify-between'>Informations administrateur <BsShieldLockFill className='text-yellow-300' /></h1>
                <label className="block w-full">
                  <span className="text-gray-700 flex justify-between">
                    Pseudo
                  </span>
                  <input
                  disabled value={user.username}
                    type="text"
                    className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-blue-50 focus:ring-0"
                  />
                </label>
                <label className="block w-full">
                  <span className="text-gray-700 flex justify-between">
                    Nom
                  </span>
                  <input
                  disabled value={user.nom}
                    type="text"
                    className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-blue-50 focus:ring-0"
                  />
                </label>
                <label className="block w-full">
              <span className="text-gray-700 flex justify-between">
                Prénom
              </span>
              <input
              disabled value={user.prenom}
                type="text"
                className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-blue-50 focus:ring-0"
              />
            </label>
            <label className="block w-full">
              <span className="text-gray-700 flex justify-between">
                Email
              </span>
              <input
              disabled value={user.email}
                type="text"
                className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-blue-50 focus:ring-0"
              />
            </label>
            <label className="block w-full">
              <span className="text-gray-700 flex justify-between">
                Date d'inscription sur le site
              </span>
              <input
              disabled value={`Le `+moment(user.created_at).format('DD/MM/YYYY à HH:mm')}
                type="text"
                className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-blue-50 focus:ring-0"
              />
            </label>
            <label className="block w-full">
              <span className="text-gray-700 flex justify-between">
                Dernière connexion
              </span>
              <input
              disabled value={`Le `+moment(user.last_connection).format('DD/MM/YYYY à HH:mm')}
                type="text"
                className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-blue-50 focus:ring-0"
              />
            </label>
              </div>
            }
      </div>
    );
  }

  return <div>Loading...</div>;
};

export default UserProfile;
