import { IAuthUser } from "../../Interfaces/IAuthUser";

interface IProps{
    user: IAuthUser
}

const UserGame = (props:IProps) => {
    return (
    <a href={`/profil/${props.user.id}`} className="flex space-x-2 p-2 bg-gray-50 shadow">
        <div>
            <img height={68} width={68} src={props.user.steam_profil?.avatarmedium} className="rounded border-2 border-blue-300"/>
        </div>
        
        <div>
            <h1 className="font-semibold">{props.user.username}</h1>
            <span>{props.user.prenom} {props.user.nom.substring(0,1)}.</span>
        </div>
    </a>);
}

export default UserGame;