import Config from "../../Config";

interface IImages {
  url: string;
  label: string;
}

const Images: IImages[] = [
  {
    url: "https://img.lafrancis.fr/gagant_ete_2023.jpg",
    label: "Edition été 2023",
  },
  {
    url: "https://img.lafrancis.fr/gagnant_septembre_2022.jpg",
    label: "Edition septembre 2022",
  },
  {
    url: "https://img.lafrancis.fr/gagnant_ete_2021.jpeg",
    label: "Edition été 2021",
  },
  {
    url: "https://img.lafrancis.fr/gagnant_ete_2020.jpeg",
    label: "Edition été 2020",
  },
];

const AuthHome = () => {
  return (
    <div>
      <h1 className="font-bold sm:text-xl text-base">
        Bienvenue sur le site de{" "}
        <span className="text-blue-800">{Config.sitename}</span>
      </h1>

      <h2 className="text-sm sm:text-base">
        Voici les gagnants des éditions précédentes
      </h2>

      <div className="space-y-2">
        {Images.map((i: IImages) => (
          <div className="p-4 bg-gray-100 rounded shadow">
            <img src={i.url} className="rounded h-96" />
            <span className="text-gray-700 font-semibold">{i.label}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AuthHome;
