import { useEffect } from "react";
import { GetEvents } from "../../../Services/EventsService";
import { useState } from "react";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";

enum EventType {
  SIGNIN = "Inscription lan",
  PAYMENT = "Nouveau paiement",
  LAN_CREATED = "LAN créée",
  MAIL_SENDED = "Mail envoyé",
  MAIL_CONFIRM_SUB = "Confirmation lan",
  NEW_USER = "Nouveau joueur",
  MAIL_FORGET = "Mot de passe oublié",
}

export interface IEvent {
  id: number;
  type: number;
  description: string;
  created_at: string;
  realType: string;
}

const Events = () => {
  const [rowData, setRowData] = useState<IEvent[]>([]);
  const [columnDefs] = useState([
    {
      field: "type",
      headerName: "Action",
      cellRenderer: (props: any) => {
        return <>{EnumToString(props.value)}</>;
      },
    },
    { field: "description", resizable: true, minWidth: 500 },
    {
      field: "created_at",
      cellRenderer: (props: any) => {
        return <>{moment(props.value).format("DD/MM/YYYY à HH:mm")}</>;
      },
    },
  ]);

  useEffect(() => {
    GetEvents().then((events: IEvent[] | undefined) => {
      if (!events) return;
      setRowData(events);
    });
  }, []);

  const EnumToString = (event: any) => {
    switch (event) {
      case 0:
        return "Inscription lan";
        break;
      case 1:
        return "Nouveau paiement";
        break;
      case 2:
        return "Création d'une LAN";
        break;
      case 3:
        return "Envoi d'un mail";
        break;
      case 4:
        return "Mail confirmation LAN";
        break;
      case 5:
        return "Nouveau joueur";
        break;
      case 6:
        return "Mot de passe oublié";
        break;
    }
  };

  return (
    <>
      <h1 className="text-2xl font-semibold">Événements</h1>
      <h2 className="font-semibold">Derniers événements</h2>
      <div className="ag-theme-balham-dark" style={{ height: 500 }}>
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          domLayout={"autoHeight"}
        ></AgGridReact>
      </div>
    </>
  );
};

export default Events;
