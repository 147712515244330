import { FaNetworkWired } from "react-icons/fa";
import { RiMoneyEuroCircleFill } from "react-icons/ri";
import { TiGroup } from "react-icons/ti";
import { IAuthUser } from "../../Interfaces/IAuthUser";
import { BsCalendarFill } from "react-icons/bs";

const links = [
  {
    label: "Utilisateurs",
    icon: <TiGroup className="mr-2" />,
    uri: "/admin/users",
  },
  {
    label: "Gestion des lans",
    icon: <FaNetworkWired className="mr-2" />,
    uri: "/admin/lans",
  },
  {
    label: "Trésorerie",
    icon: <RiMoneyEuroCircleFill className="mr-2" />,
    uri: "/admin/tresorerie",
  },
  {
    label: "Événements",
    icon: <BsCalendarFill className="mr-2" />,
    uri: "/admin/events",
  },
];

interface IProps {
  user: IAuthUser;
}

const AdminMenu = (props: IProps): any => {
  if (!props.user.admin) return <></>;
  return (
    <>
      <h1 className="font-bold ml-5 mt-3 pt-5 border-t-2 border-gray-300">
        Administration
      </h1>
      {links.map((l) => {
        return (
          <a
            key={l.uri}
            href={l.uri}
            className={`flex items-center py-2 px-4 m-2 rounded ${
              window.location.pathname == l.uri ? "menu-item-active" : ""
            }`}
          >
            <span>{l.icon}</span>
            <span className="hidden sm:block">{l.label}</span>
          </a>
        );
      })}
    </>
  );
};

export default AdminMenu;

/*

        */
