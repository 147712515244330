import { useState, useEffect } from "react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";
import { AgGridReact } from "ag-grid-react";

import { GetUsers } from "../../../Services/AdminService";
import moment from "moment";

const Users = () => {
  const [rowData, setRowData] = useState([]);

  const [columnDefs] = useState([
    { field: "username", sortable: true, headerName: "Pseudo" },
    { field: "nom", sortable: true },
    { field: "prenom", sortable: true },
    { field: "email", sortable: true },
    {
      field: "created_at",
      headerName: "Créé le",
      sortable: true,
      cellRenderer: (props: any) => {
        return <>{moment(props.value).format("DD/MM/YYYY à HH:mm")}</>;
      },
    },
    {
      field: "last_connection",
      headerName: "Dernière connexion le",
      sortable: true,
      cellRenderer: (props: any) => {
        return <>{moment(props.value).format("DD/MM/YYYY à HH:mm")}</>;
      },
    },
    {
      field: "steamid",
      headerName: "SteamID",
      cellRenderer: (props: any) => {
        return (
          <a
            href={`https://steamcommunity.com/profiles/${props.value}`}
            target="_blank"
          >
            {props.value}
          </a>
        );
      },
    },
    {
      field: "admin",
      sortable: true,
      headerName: "Organisateur",
      cellRenderer: (props: any) => {
        return <input type="checkbox" checked={props.value} readOnly />;
      },
    },
  ]);

  useEffect(() => {
    GetUsers().then((users: any) => {
      //console.log(users);
      setRowData(users);
    });
  }, []);

  return (
    <div>
      <h1 className="text-2xl font-semibold">Utilisateurs</h1>
      <h2 className="font-semibold">
        Liste des utilisateurs inscrits sur le site
      </h2>

      <div className="ag-theme-balham-dark" style={{ height: 500 }}>
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          domLayout={"autoHeight"}
        ></AgGridReact>
      </div>
    </div>
  );
};

export default Users;

// {make: "Toyota", model: "Celica", price: 35000},
