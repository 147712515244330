import { useState, useEffect } from "react";
import { ILan } from "../../../Interfaces/ILan";
import { GetLansList } from "../../../Services/LanService";
import { AgGridReact } from "ag-grid-react";
import { FaEdit } from "react-icons/fa";
import moment from "moment";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";
import { GetBalance } from "../../../Services/AdminService";

const AdminLans = () => {
  const [lansList, setLansList] = useState<ILan[]>([]);

  useEffect(() => {
    GetLansList().then((datas: ILan[]) => {
      setLansList(datas);
    });
  }, []);

  const [columnDefs] = useState([
    {
      field: "lan.title",
      headerName: "Libellé",
    },
    {
      field: "lan.address",
      headerName: "Adresse",
    },
    {
      field: "nbparticipants",
      headerName: "Nombre de participant",
      cellRenderer: (props: any) => {
        return (
          <>
            {props.value}/{props.data.lan.participants}
          </>
        );
      },
    },
    {
      field: "lan.engagement",
      headerName: "Engagement joueur",
      cellRenderer: (props: any) => {
        return <>{props.value}€</>;
      },
    },
    {
      field: "lan.engagement_visiteur",
      headerName: "Engagement visiteur",
      cellRenderer: (props: any) => {
        return <>{props.value}€</>;
      },
    },
    {
      field: "lan.start_at",
      headerName: "Dates",
      cellRenderer: (props: any) => {
        return (
          <>
            Du {moment(props.value).format("DD/MM/YYYY")} au{" "}
            {moment(props.data.lan.end_at).format("DD/MM/YYYY")}
          </>
        );
      },
    },
    {
      field: "lan.stop_at",
      headerName: "Fin d'inscription",
      cellRenderer: (props: any) => {
        return <>{moment(props.value).format("DD/MM/YYYY")}</>;
      },
    },
    {
      headerName: "Actions",
      cellRenderer: (props: any) => {
        return (
          <a href={`/admin/lans/edit/${props.data.lan.id}`}>
            <FaEdit className=" text-xl" />
          </a>
        );
      },
    },
  ]);

  return (
    <>
      <h1 className="text-2xl font-semibold mb-5">Gestion des lans</h1>

      <a
        href="/admin/lans/new"
        className="bg-blue-400 hover:bg-blue-500 px-4 py-1 text-white rounded cursor-pointer font-semibold"
      >
        Créer une lan
      </a>

      <div className="ag-theme-balham-dark mt-5" style={{ height: 500 }}>
        <AgGridReact
          rowData={lansList}
          columnDefs={columnDefs}
          domLayout={"autoHeight"}
        ></AgGridReact>
      </div>
    </>
  );
};

export default AdminLans;
