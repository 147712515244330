import { useContext } from 'react';
import { AuthContext } from '../..';
import { IAuthUser } from '../../Interfaces/IAuthUser';

const Account = () => {
  let user: IAuthUser = useContext(AuthContext) as IAuthUser;

  return (
    <div>
      <div className="flex">
        <img src={user.steam_profil?.avatarmedium} className="rounded shadow" />
        <div className="flex flex-col justify-center ml-2">
          <h1 className="font-semibold">{user.username}</h1>
          <span className="text-sm">
            {user.prenom} {user.nom}
          </span>
        </div>
      </div>
      <div className="mt-5">
        <fieldset className="border-2 p-2">
          <legend className="px-2">Modification de votre profil</legend>
          <label className="block w-full">
            <span className="text-gray-700">Votre nom</span>
            <input
              type="text"
              value={user.nom}
              className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-blue-50 focus:ring-0"
              required
            />
          </label>
          <label className="block w-full">
            <span className="text-gray-700">Votre prénom</span>
            <input
              type="text"
              value={user.prenom}
              className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-blue-50 focus:ring-0"
              required
            />
          </label>
        </fieldset>
      </div>
    </div>
  );
};

export default Account;
