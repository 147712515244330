import Config from '../Config';
import { ILan, ILanParticipant } from '../Interfaces/ILan';
import { ILanWithParticipants } from '../Interfaces/ILanWithParticipants';
import { GetStoredToken } from './UserService';
import qs from 'querystring';

const GetLansList = async () => {
  const token = GetStoredToken()[1];
  if (!token) return;
  const request = await fetch(`${Config.baseURL}/lan/lans`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const result = await request.json();
  return result;
};

const GetLanById = async (id: number): Promise<ILan | null> => {
  const token = GetStoredToken()[1];
  if (!token) return null;
  const req = await fetch(`${Config.baseURL}/lan/lan/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (req.status === 200) {
    const res = await req.json();
    return res;
  }
  return Promise.reject(new Error("404"));
};

const GetUserLanDetail = async (lanID: string) => {
  const token = GetStoredToken()[1];
  if (!token) return null;
  const req = await fetch(`${Config.baseURL}/lan/user/${lanID}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (req.status === 200) {
    const res = await req.json();
    return res;
  }
  return null;
};

const GetLanDetailsById = async (
  id: number
): Promise<ILanWithParticipants | null> => {
  const token = GetStoredToken()[1];
  if (!token) return null;
  const req = await fetch(`${Config.baseURL}/lan/details/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (req.status === 200) {
    const res = await req.json();
    return await res;
  }
  return null;
};

const GetLanParticipantByLanID = async (
  id
): Promise<ILanParticipant | null> => {
  const token = GetStoredToken()[1];
  if (!token) return null;
  const req = await fetch(`${Config.baseURL}/lan/lans`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (req.status === 200) {
    const res: ILanParticipant[] = await req.json();
    const filter = res.filter((l: ILanParticipant) => l.lan.id == id);
    return filter[0];
  } else {
    return null;
  }
};

const SigninLan = async (
  lanid: number,
  commentaire: string,
  PC: string,
  joueur: boolean,
  tournament: boolean
): Promise<boolean> => {
  const token = GetStoredToken()[1];
  if (!token) return false;
  const body = qs.stringify({
    lan: lanid,
    equipment: PC == 'owned' ? true : false,
    commentaire: commentaire,
    visiteur: !joueur,
    tournament:tournament
  });

  //console.log(body);

  const req = await fetch(`${Config.baseURL}/lan/signin`, {
    method: 'post',
    body: body,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    },
  });
  if (req.status === 200) {
    return true;
  } else {
    //console.log(await req.text());
    return false;
  }
};
const PaiementSuccess = async (
  details: any,
  data: any,
  lan: ILanParticipant,
  amount: number
) => {
  const token = GetStoredToken()[1];
  if (!token) return false;
  const body = qs.stringify({
    create_time: details.create_time,
    orderID: data.orderID,
    payerID: data.payerID,
    status: details.status,
    paypal_id: details.id,
    amount: amount
  });
  const req = await fetch(`${Config.baseURL}/lan/paiement/${lan.lan.id}`, {
    method: 'post',
    body: body,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    },
  });

  if (req.status === 200) {
    return true;
  } else {
    return false;
  }
};

const UpdateLan = async(lan:ILan):Promise<boolean> => {
  const token = GetStoredToken()[1];
  if(!token) return false;
  const req = await fetch(`${Config.baseURL}/lan/update/${lan.id}`, {
    method: 'put',
    body: JSON.stringify(lan),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  if (req.status === 200) {
    return Promise.resolve(true);
  } else {
    return Promise.reject(req.body);
  }
}

const NewLan = async(lan:ILan):Promise<boolean> => {
  const token = GetStoredToken()[1];
  if(!token) return false;
  const req = await fetch(`${Config.baseURL}/lan/create`, {
    method: 'post',
    body: JSON.stringify(lan),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  if (req.status === 200) {
    return Promise.resolve(true);
  } else {
    return Promise.reject(req.body);
  }
}

export {
  GetLansList,
  GetLanById,
  GetLanDetailsById,
  GetLanParticipantByLanID,
  SigninLan,
  PaiementSuccess,
  GetUserLanDetail,
  UpdateLan,
  NewLan
};
