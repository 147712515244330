import { useEffect, useState } from 'react';
import { IAuthUser } from '../../Interfaces/IAuthUser';
import { isTokenValid, UpdatePassword } from '../../Services/UserService';
import logo from '../../Images/logo.png';

interface IProps {
  location: any;
}

const AuthValidation = (props: IProps) => {
  const [user, setUser] = useState<IAuthUser | null>();
  const [token, setToken] = useState<string>('');

  useEffect(() => {
    const token: string = props.location.search.split('=')[1];
    isTokenValid(token).then((valid: IAuthUser) => {
      if (valid) {
        setUser(valid);
        setToken(token);
      } else {
        document.location.href = '/';
      }
    });
  }, []);

  const [password, setPassword] = useState<string>('');
  const [confirmePassword, setconfirmePassword] = useState<string>('');

  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<boolean>();

  async function submitForm(e) {
    e.preventDefault();
    if (password.length > 0 && confirmePassword.length > 0) {
      if (password !== confirmePassword) {
        // Password pas cohérents
        setError('Les mots de passes doivent être identiques');
      } else {
        // c'est good
        setError('');
        if (password.length < 5) {
          setError(
            'Par mesure de sécurité, les mots de passe doivent contenir 5 caractères minimum'
          );
        } else {
          setSuccess(await UpdatePassword(password, token));
        }
      }
    }
  }

  if (!user) {
    return <div>Chargement...</div>;
  }

  return (
    <div className="flex">
      <div className="mx-auto mt-10 w-full md:w-full lg:w-1/4 flex flex-col items-center space-y-4">
        <a href="/">
          <img src={logo} className="h-24 w-24" />
        </a>
        <form
          className={`w-full flex-col bg-white rounded-md shadow p-4 space-y-4 ${
            success ? 'hidden' : 'flex'
          }`}
          onSubmit={submitForm}
        >
          <h2>
            Bienvenue <span className="font-bold">{user.username}</span>,
            <p>Merci de choisir un mot de passe</p>
          </h2>
          <label className="block w-full">
            <span className="text-gray-700">Votre mot de passe</span>
            <input
              type="password"
              autoComplete="password"
              className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-blue-50 focus:ring-0"
              required
              onChange={(e) => setPassword(e.target.value)}
            />
          </label>
          <label className="block w-full">
            <span className="text-gray-700">Confirmation du mot de passe</span>
            <input
              type="password"
              autoComplete="password"
              className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-blue-50 focus:ring-0"
              required
              onChange={(e) => setconfirmePassword(e.target.value)}
            />
          </label>
          {error.length > 0 && (
            <div className="h-16 p-4 bg-red-400 text-white rounded flex justify-center items-center">
              <span>{error}</span>
            </div>
          )}
          <button className="from-green-500 to-green-600 bg-gradient-to-r py-2 px-4 rounded shadow block my-4 text-white font-bold">
            Valider
          </button>
        </form>
        <div
          className={`w-full flex-col bg-white rounded-md shadow p-4 space-y-4 ${
            success ? 'flex' : 'hidden'
          }`}
        >
          <p>
            Votre compte a été mis à jour, vous pouvez dès maintenant vous
            connectez.
          </p>
          <a href="/" className="text-blue-500 text-sm self-end">
            Retour à l'accueil
          </a>
        </div>
      </div>
    </div>
  );
};

export default AuthValidation;
