import { useEffect, useState } from "react";
import { ILan } from "../../../Interfaces/ILan";
import { NewLan } from "../../../Services/LanService";
import { useParams, Redirect } from "react-router-dom";
import moment from "moment";

const CreateLan = () => {
  const [lan, setLan] = useState<ILan>();

  const [buttonDisable, setButtonDisable] = useState<boolean>(false);
  const [redirect, setRedirect] = useState<boolean>(false);

  useEffect(() => {
    setLan({
      address: "",
      current: false,
      description: "",
      end_at: new Date(),
      engagement: 0,
      engagement_visiteur: 0,
      linktocgu: "",
      participants: 0,
      start_at: new Date(),
      stop_at: new Date(),
      title: "",
      user: 0,
      id: 0,
      public:true
    });
  }, []);

  const changeData = (key, value) => {
    let tmpLan = lan;
    if (tmpLan && lan != null && lan != undefined) {
      setLan({ ...lan, [key]: value });
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (lan) {
      setButtonDisable(true);
      NewLan(lan)
        .then((value: boolean) => {
          setButtonDisable(false);
          setRedirect(true);
        })
        .catch((error: any) => {
          setButtonDisable(false);
          console.log(error);
        });
    }
  };

  if (redirect) {
    return <Redirect to="/admin/lans" />;
  }

  return (
    <div className="w-full sm:w-10/12 mx-auto">
      <form className="flex flex-col gap-4" onSubmit={onSubmit}>
        <label className="block w-full bg-blue-100 p-2 rounded shadow-sm">
          <span className="text-gray-700 flex justify-between font-semibold">
            Libellé de la lan
          </span>
          <input
            onChange={(props: any) => {
              changeData("title", props.target.value);
            }}
            value={lan?.title}
            type="text"
            className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-blue-50 focus:ring-0"
          />
        </label>
        <div className="flex gap-4">
          <label className="block w-full bg-blue-100 p-2 rounded shadow-sm">
            <span className="text-gray-700 flex justify-between font-semibold">
              Date de début
            </span>
            <input
              onChange={(props: any) => {
                changeData("start_at", props.target.value);
              }}
              value={moment(lan?.start_at).format("YYYY-MM-DD")}
              type="date"
              className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-blue-50 focus:ring-0"
            />
          </label>
          <label className="block w-full bg-blue-100 p-2 rounded shadow-sm">
            <span className="text-gray-700 flex justify-between font-semibold">
              Date de fin
            </span>
            <input
              onChange={(props: any) => {
                changeData("end_at", props.target.value);
              }}
              value={moment(lan?.end_at).format("YYYY-MM-DD")}
              type="date"
              className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-blue-50 focus:ring-0"
            />
          </label>
        </div>
        <label className="block w-full bg-blue-100 p-2 rounded shadow-sm">
          <span className="text-gray-700 flex justify-between font-semibold">
            Fin des inscriptions
          </span>
          <input
            onChange={(props: any) => {
              changeData("stop_at", props.target.value);
            }}
            value={moment(lan?.stop_at).format("YYYY-MM-DD")}
            type="date"
            className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-blue-50 focus:ring-0"
          />
        </label>
        <label className="block w-full bg-blue-100 p-2 rounded shadow-sm">
          <span className="text-gray-700 flex justify-between font-semibold">
            Adresse
          </span>
          <input
            onChange={(props: any) => {
              changeData("address", props.target.value);
            }}
            value={lan?.address}
            type="text"
            className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-blue-50 focus:ring-0"
          />
        </label>
        <label className="block w-full bg-blue-100 p-2 rounded shadow-sm">
          <span className="text-gray-700 flex justify-between font-semibold">
            Description
          </span>
          <textarea
            onChange={(props: any) => {
              changeData("description", props.target.value);
            }}
            className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-blue-50 focus:ring-0"
            rows={10}
            value={lan?.description}
          ></textarea>
        </label>
        <label className="block w-full bg-blue-100 p-2 rounded shadow-sm">
          <span className="text-gray-700 flex justify-between font-semibold">
            Nombre de participant maximum : {lan?.participants}
          </span>
          <input
            onChange={(props: any) => {
              changeData("participants", props.target.value);
            }}
            value={lan?.participants.toString()}
            min={0}
            max={50}
            type="range"
            className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-blue-50 focus:ring-0"
          />
        </label>
        <div className="flex gap-4">
          <label className="block w-full bg-blue-100 p-2 rounded shadow-sm">
            <span className="text-gray-700 flex justify-between font-semibold">
              Engagement joueur
            </span>
            <input
              onChange={(props: any) => {
                changeData("engagement", props.target.value);
              }}
              value={lan?.engagement.toString()}
              type="number"
              className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-blue-50 focus:ring-0"
            />
          </label>
          <label className="block w-full bg-blue-100 p-2 rounded shadow-sm">
            <span className="text-gray-700 flex justify-between font-semibold">
              Engagement visiteur
            </span>
            <input
              onChange={(props: any) => {
                changeData("engagement_visiteur", props.target.value);
              }}
              value={lan?.engagement_visiteur.toString()}
              type="number"
              className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-blue-50 focus:ring-0"
            />
          </label>
        </div>
        <div>
          <input type="checkbox" id="isPublic" checked={!lan?.public} onChange={(props:any) => {
            changeData('public', props.target.checked);
          }}/>
          <label htmlFor="isPublic"> Limiter aux organisateurs</label>
        </div>
        <input
          type="submit"
          value="Créer"
          className="h-14 font-bold bg-green-400 text-white rounded shadow cursor-pointer disabled:bg-green-50"
          disabled={buttonDisable}
        />
      </form>
    </div>
  );
};

export default CreateLan;
