import Config from '../../Config';
import logo from '../../Images/logo.png';
import { ImProfile, ImSteam2 } from 'react-icons/im';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useEffect, useState } from 'react';
import { RegisterUser } from '../../Services/UserService';

const Register = () => {
  let [openDialog, setOpenDialog] = useState<boolean>(false);

  const [nom, setNom] = useState<string>('');
  const [prenom, setPrenom] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [pseudo, setPseudo] = useState<string>('');
  const [link, setLink] = useState<string>('');

  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [bformError, setbFormError] = useState<boolean>(false);
  const [mFormError, setmFormError] = useState<string>('');
  const [bRegisterSuccess, setbRegisterSuccess] = useState<boolean>(false);

  function submitForm(e) {
    e.preventDefault();
    setbFormError(false);
    setmFormError('');
    setFormLoading(true);
    RegisterUser(nom, prenom, email, link, pseudo)
      .then((res) => {
        if (!res[0]) {
          // Erreur lors de l'inscription, affichage d'une erreur
          setbFormError(true);
          if (res[1] == 'MailAlreadyUsed') {
            setmFormError('Cette adresse mail existe déjà.');
            setFormLoading(false);
          }
        } else {
          // Inscription réalisée, on cache le formulaire et affiche un super message
          setbFormError(false);
          setmFormError('');
          setbRegisterSuccess(true);
        }
      })
      .catch((reason:any) => alert(reason));
  }

  return (
    <div className="h-screen">
      <div className="mx-auto mt-10 w-full md:w-full lg:w-1/3 items-center space-y-4">
        <div className="flex flex-col items-center">
          <a href="/">
            <img src={logo} className="h-24 w-24" />
          </a>
          <h1 className="text-xl font-semibold mb-4">
            S'inscrire à{' '}
            <span className="text-blue-800 font-bold">{Config.sitename}</span>
          </h1>
        </div>
        {bRegisterSuccess ? (
          <div>
            <div className="bg-white shadow-lg rounded p-4">
              <p>
                Bonjour {pseudo}, votre inscription a été prise en compte, un
                mail vient de vous être envoyé pour la valider.
              </p>
            </div>
            <a href="/" className="m-4 flex justify-center text-blue-500">
              Retourner à l'accueil
            </a>
          </div>
        ) : (
          <form className="m-4" onSubmit={submitForm}>
            <h2 className="text-lg font-semibold text-green-400 flex items-center">
              <ImProfile className="mr-2" /> Vos informations personnelles
            </h2>
            <div className="mt-4 p-4 bg-white rounded shadow font-semibold space-y-4">
              <div className="flex space-x-4">
                <label className="block w-full">
                  <span className="text-gray-700">Votre nom</span>
                  <input
                    type="text"
                    onChange={(e) => setNom(e.target.value)}
                    className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-blue-50 focus:ring-0"
                    required
                  />
                </label>
                <label className="block w-full">
                  <span className="text-gray-700">Votre prénom</span>
                  <input
                    type="text"
                    onChange={(e) => setPrenom(e.target.value)}
                    className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-blue-50 focus:ring-0"
                    required
                  />
                </label>
              </div>
              <label className="block w-full">
                <span className="text-gray-700">Votre adresse mail</span>
                <input
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-blue-50 focus:ring-0"
                  required
                />
              </label>
            </div>
            <h2 className="text-lg font-semibold text-blue-400 flex items-center mt-4">
              <ImSteam2 className="mr-2" /> Vos informations de jeu
            </h2>
            <div className="mt-4 p-4 bg-white rounded shadow w-full font-semibold space-y-4">
              <label className="block w-full">
                <span className="text-gray-700">
                  Votre pseudo le plus utilisé
                </span>
                <input
                  type="text"
                  onChange={(e) => setPseudo(e.target.value)}
                  className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-blue-50 focus:ring-0"
                  required
                />
              </label>
              <label className="block w-full">
                <span className="text-gray-700 flex justify-between">
                  Lien vers votre profil Steam{' '}
                  <a
                    className="text-blue-400 cursor-pointer"
                    onClick={() => setOpenDialog(true)}
                  >
                    Où trouver ce lien ?
                  </a>
                </span>
                <input
                  type="url"
                  onChange={(e) => setLink(e.target.value)}
                  className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-blue-50 focus:ring-0"
                  required
                />
              </label>
            </div>
            {bformError && (
              <div className="bg-red-500 rounded shadow mt-5 p-2 text-white font-semibold">
                <h2>Erreur</h2>
                <p className="font-normal">{mFormError}</p>
              </div>
            )}
            <button className="font-semibold shadow-lg mt-4 mb-10 rounded p-4 w-full text-white bg-gradient-to-r from-green-500 to-blue-400">
              {!formLoading ? (
                <>Valider mon inscription</>
              ) : (
                <div className="flex items-center justify-center">
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Chargement
                </div>
              )}
            </button>
          </form>
        )}
      </div>

      <Transition appear show={openDialog} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={() => setOpenDialog(false)}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Trouver le lien de votre compte
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-sm text-gray-500 py-4">
                    <a
                      className="p-4 bg-blue-400 shadow-lg rounded text-white"
                      href="https://support.ubisoft.com/fr-FR/faqs/000027522#:~:text=Cliquez%20sur%20Interface.&text=Assurez%2Dvous%20de%20cocher%20Afficher,Steam%20lorsqu'elle%20est%20disponible.&text=Cliquez%20sur%20OK%20pour%20enregistrer,votre%20nom%20d'utilisateur%20Steam."
                      target="_blank"
                    >
                      Suivre le tutoriel d'Ubisoft
                    </a>
                  </p>
                </div>

                <div className="mt-4">
                  <button
                    type="button"
                    className="float-right disabled:text-red-800 inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    onClick={() => setOpenDialog(false)}
                  >
                    Fermer
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default Register;
