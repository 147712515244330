import { AgGridReact } from "ag-grid-react";
import { useEffect, useState } from "react";
import {
  PaypalTransaction,
  TransactionDetail,
} from "../../../Interfaces/IPaypalTransaction";
import { GetBalance, GetTransactions } from "../../../Services/AdminService";
import moment from "moment";
import "moment/locale/fr";
moment.locale("fr");

const Tresorerie = () => {
  const [balance, setBalance] = useState<number>(0);

  const [transactions, setTransactions] = useState<TransactionDetail[]>();

  const [search, setSearch] = useState<boolean>(false);

  const [startDate, setStartDate] = useState<Date>(
    new Date(moment().startOf("month").format("YYYY-MM-DD hh:mm"))
  );
  const [endDate, setEndDate] = useState<Date>(
    new Date(moment().endOf("month").format("YYYY-MM-DD hh:mm"))
  );

  const [columnDefs] = useState([
    {
      field: "transaction_info.transaction_initiation_date",
      headerName: "Date",
      sortable: true,
      cellRenderer: (props: any) => {
        return <>{moment(props.value).format("YYYY-MM-DD hh:mm")}</>;
      },
    },
    {
      field: "payer_info.payer_name.surname",
      headerName: "Nom",
      sortable: true,
    },
    {
      field: "payer_info.payer_name.given_name",
      headerName: "Prénom",
      sortable: true,
    },
    {
      field: "payer_info.email_address",
      headerName: "Email",
      sortable: true,
    },
    {
      field: "transaction_info.transaction_amount.value",
      headerName: "Montant",
    },
    {
      field: "transaction_info.fee_amount.value",
      headerName: "Frais",
    },

    {
      headerName: "Total",
      cellRenderer: (props: any) => {
        let datas: TransactionDetail = props.data;
        if (
          datas.transaction_info.transaction_amount &&
          datas.transaction_info.fee_amount
        ) {
          return (
            <>
              {Number(datas.transaction_info.transaction_amount.value) -
                Number(datas.transaction_info.fee_amount.value)}
            </>
          );
        } else {
          return <>{datas.transaction_info.transaction_amount.value}</>;
        }
      },
    },
  ]);

  useEffect(() => {
    GetBalance()
      .then((datas: any) => setBalance(datas.L_AMT0))
      .catch(() => {
        console.log("ERREUR");
      });
  }, []);

  const updateData = () => {
    setSearch(true);
    GetTransactions(startDate, endDate)
      .then((datas: PaypalTransaction) => {
        setTransactions(datas.transaction_details);
        console.log(datas);
      })
      .catch(() => {
        console.log("ERREUR TRANSACTIONS");
      });
  };

  return (
    <>
      <h1 className="text-2xl font-semibold mb-5">Trésorerie</h1>

      <div className="flex flex-col items-center">
        <h2>Actuellement sur le compte Paypal</h2>
        <div className="text-4xl mb-5 font-extrabold text-green-500">
          {balance}
          <span>€</span>
        </div>
      </div>
      <h2 className="text-xl font-semibold mb-5">Mouvements Paypal</h2>
      <div className="flex gap-4 items-center">
        <input
          type="date"
          onChange={(props: any) => setStartDate(new Date(props.target.value))}
          value={moment(startDate).format("YYYY-MM-DD")}
        />
        <span>au</span>
        <input
          type="date"
          onChange={(props: any) => setEndDate(new Date(props.target.value))}
          value={moment(endDate).format("YYYY-MM-DD")}
        />
        <button
          className="p-3 bg-green-300 rounded"
          onClick={() => updateData()}
        >
          Afficher
        </button>
      </div>
      <span>On ne peut afficher les transactions sur 31 jours MAX</span>
      <div className="ag-theme-balham-dark mt-5" style={{ height: 500 }}>
        {search && (
          <AgGridReact
            rowData={transactions}
            columnDefs={columnDefs}
            domLayout={"autoHeight"}
          ></AgGridReact>
        )}
      </div>
    </>
  );
};

export default Tresorerie;
